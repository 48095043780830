import {
  ApolloClient,
  InMemoryCache,
  // ApolloProvider,
  createHttpLink,
  split,
  // gql,
} from '@apollo/client';
// import { typeDefs } from './typeDefs';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

// console.log('🚀 ~ file: index.js:15 ~ token', token);

// console.log(process.env.REACT_APP_GRAPHQL_URI);
const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION,
    connectionParams: () => {
      const token = JSON.parse(localStorage.getItem('userData'))?.accessToken || '';
      return {
        authorization: token ? `Bearer ${token}` : '',
      }
    },
  })
);
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  // const token = JSON.parse(
  //   JSON.parse(localStorage.getItem('persist:nextjs')).auth
  // ).accessToken;
  // const token = localToken();
  const token = JSON.parse(localStorage.getItem('userData'))?.accessToken || '';
  // console.log(token);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      Platform: 'web',
    },
  };
});
// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);
const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache(),
});

export default client;
